import React, { useState, useEffect, useCallback } from 'react';
import Input from 'common/components/Input';
import Button from 'common/components/Button';
import { Message, Spinner, Text } from 'theme-ui'


import SectionWrapper, { TextWrapper } from './form.style';
import '@redq/reuse-modal/es/index.css';

const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState()
  const [values, setValues] = useState()
  const [action, setAction] = useState()

  const handleSubmit = (e, { values, action } = {}) => {
    e.preventDefault()
    if (values) setValues(values)
    if (action) setAction(action)
    setTarget(e.target)
    setSubmitting(true)
  }

  const sendValues = useCallback(() => {
    const form = new FormData(target)
    const formData = new URLSearchParams(values || form).toString()

    fetch(action || target.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      },
      body: formData
    })
      .then(() => {
        target.reset()
        setSuccess(true)
      })
      .catch(error => {
        setSuccess(false)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [target])

  useEffect(() => {
    if (submitting) {
      sendValues()
    }
  }, [submitting, sendValues])

  const reset = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
    setValues(undefined)
    setAction(undefined)
  }, [])

  return (
    <SectionWrapper>
        <TextWrapper className="header">
          <h3>Que ce soit pour demander un devis ou avoir plus d'information concernant le nettoyage cryogénique, n'hésitez pas à nous contacter. Vous pouvez nous envoyer un mail avec le formulaire ci-dessous, ou nous appeler directement au 07 72 23 28 39.</h3>
          <h3>Pour que votre demande de devis soit traitée le plus efficacement et rapidement possible, veuillez joindre avec le formulaire une photo du véhicule à nettoyer.</h3>
        </TextWrapper>
          <form 
            className="container-form"
            name="contact" 
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="subject" value="Formulaire de contact du site" />
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <Input className="form-input" required inputType="text" name="name" isMaterial label="Votre nom :" />
            <Input className="form-input" required inputType="email" name= "email" isMaterial label="Votre adresse email :" />
            <Input className="form-input" required inputType="tel" name= "phone" pattern="[0-9]{10}" isMaterial label="Votre numéro de téléphone :" />
            <Input className="form-input" required inputType="textarea" name= "message" isMaterial label="Votre message :" />
            <label>
              <input name="file" type="file" accept='image/*'/>
            </label>
            {success === true && reset && (
              <Message variant='success'>
                Merci pour votre message. On revient rapidement vers vous !
              </Message>
            )}
            {success === false && (
              <Message variant='error'>
                Quelque chose s'est mal passé. Réessayez plus tard !
              </Message>
            )}
            <Button title="Envoyer" disabled={success || submitting} type='submit' required>
              {submitting && <Spinner size='20' />}
            </Button>
          </form>
    </SectionWrapper>
  );
};
export default ContactForm;
