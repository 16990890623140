import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

const SectionWrapper = styled.section`
  margin-top: 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%
  @media (max-width: 1600px) {
    padding-top: 0;
    padding-bottom: 100px;
  }
  > div.container {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 624px) {
      flex-direction: column;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    width: 70%;
    @media only screen and (max-width: 480px) {
      width: 80%;
    }
  }
  .section-header-two {
    text-align: center;
    max-width: 1000px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
  .container-form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width:70%;
  }
  .form-input {
    margin-bottom: 3em;
    width: 30em;
    @media only screen and (max-width: 624px) {
      width: 20em;
    }
  }
  input[type='number'] {
    -moz-appearance:textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  button {
    margin-top: 3em;
    margin-bottom: 3em;
    text-align: center;
  }
`;

export const TextWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  margin: 4em;
  @media (max-width: 1600px) {
    width: 90%;
    margin-left: 70px;
  }
  @media (max-width: 768px) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export default SectionWrapper;
