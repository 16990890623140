import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Container from 'common/components/UI/Container';
import Input from 'common/components/Input';
import Button from 'common/components/Button';


import Navbar from 'containers/AppModern/Navbar';
import Banner from 'containers/AppMinimal/Banner';
import Footer from 'containers/AppMinimal/Footer';
import ContactForm from 'containers/AppMinimal/Form';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import Seo from 'components/seo';

const Contact = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Cryogenite" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          
          <ContactForm />
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default Contact;
